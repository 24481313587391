<template>
  <div style="position: relative;">
    <!--<van-uploader v-model="imageArray" multiple :before-read="onClickUploadImageBefore" :after-read="onClickUploadImage1" :before-delete ="removeImageList" />-->
    <van-uploader v-model="imageArray" multiple @click-upload="onClickUploadImageBefore" @change="changeUploadImage"  :after-read="onClickUploadImage1" :before-delete ="removeImageListV2" :disabled="isEditSaved || !field.Editable"/>
  </div>
</template>

<script>
import { Uploader, Loading, Image } from 'vant'
import {IMAGE_ROOT} from '../../../../com/config'
export default {
    name: 'nxMobilePictureList',
    components: {
        [Image.name]: Image,
        [Uploader.name]: Uploader,
        [Loading.name]: Loading

    },
    props: {
        pageSetup: Object,
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        value: String,
        config: Object,
        children: Array,
        cssdiy: Object,
        label: String,
        isEditSaved: Boolean,
        onClickUploadImage: Function,
        onClickUploadImageList: Function,
        onClickUploadAffixList: Function,
        popImageInfo: Function,
        onClickShowImage: Function,
        removeImage: Function,
        resourceHome: String,
        rowindex: Number,
        tablecode: String,
        onClickUploadAffix: Function,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        onClickUploadImageBeforeV2: Function,
        removeImageList: Function,
        dataRow: Object,
        deleteFocuseRow: Function,
        isList: {
            type: Boolean,
            default: false
        },
        refresh: {
            type: Boolean,
            default: false
        },
        previewAffix: Function,
        showPreloader: Function,
        hidePreloader: Function,
        allRefreshTable: Array,
        updateField: Boolean
    },
    watch: {
        refresh () {
            if (this.deleteIndex > -1) {
                this.imageArray.splice(this.deleteIndex, 1)
                this.deleteIndex = -1
                // this.imageArray = []
            }
        },
        async allRefreshTable () {
          if (this.allRefreshTable.indexOf(this.config.TableCode) >= 0) {
              this.imageArray = []
          }
        },
        dataSet: {
            async handler () {
                if (this.allRefreshTable.indexOf(this.config.TableCode) >= 0) {
                    this.imageArray = []
                    for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
                        const result = await this.$api.getImageOrAffixUrl({ ResourceID: this.dataSet[this.config.TableCode][i][this.config.FieldCode], ReportID:0 })
                        let index = this.imageArray.findIndex(element => element.url === (IMAGE_ROOT + result.imageinfo.Url))
                        if (this.imageArray[i] !== undefined || index >= 0) {
                            this.imageArray[i].url =  IMAGE_ROOT + result.imageinfo.Url
                            this.imageArray[i].id =  this.dataSet[this.config.TableCode][i][this.config.FieldCode]
                        } else {
                            this.imageArray.push({
                                url: IMAGE_ROOT + result.imageinfo.Url ,
                                status: '',
                                message: '',
                                id: this.dataSet[this.config.TableCode][i][this.config.FieldCode]
                            })
                        }
                    }
                }
                for (let i = this.imageArray.length - 1; i >= 0; i--) {
                    if (this.imageArray[i].file !== undefined || this.imageArray[i].content !== undefined) {
                        this.imageArray.splice(i, 1)
                    }
                }
                for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
                    if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
                        if (this.dataSet[this.config.TableCode][i][this.config.OutFieldCode] !== this.dataSet[this.tablecode][this.rowindex][this.config.MasterFieldCode]) {
                            let index = this.imageArray.findIndex(element => element.id ===  this.dataSet[this.config.TableCode][i][this.config.FieldCode])
                            if (this.imageArray[i] !== undefined || index >= 0) {
                                this.imageArray.splice(index, 1)
                            }
                            continue
                        }
                    }
                   const result = await this.$api.getImageOrAffixUrl({ ResourceID: this.dataSet[this.config.TableCode][i][this.config.FieldCode], ReportID:0 })
                    let index = this.imageArray.findIndex(element => element.url === (IMAGE_ROOT + result.imageinfo.Url))
                    if (this.imageArray[i] !== undefined || index >= 0) {
                        continue
                    }
                    this.imageArray.push({
                        url: IMAGE_ROOT + result.imageinfo.Url ,
                        status: '',
                        message: '',
                        id: this.dataSet[this.config.TableCode][i][this.config.FieldCode]
                    })
                }
            },
            deep: true
        },
        updateField () {
            let tableList = []
            tableList = this.$utils.deepClone(this.tables)
            tableList.push(this.mastertable)
            this.field = null
            for (let i = 0; i < tableList.length; i++) {
                const table = tableList[i]
                for (let j = 0; j < table.Fields.length; j++) {
                    if ((this.tablecode === undefined || table.TableCode === this.tablecode) && table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
                if (this.field !== null) {
                    break
                }
            }
        }
    },
    data () {
      return {
          field: null,
          deleteIndex: -1,
          imageArray: [],
          id: ''
      }
    },
    async mounted () {
        this.id = this.$utils.getGuid()
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            for (let j = 0; j < table.Fields.length; j++) {
                if (table.Fields[j].FieldCode === this.config.FieldCode) {
                    this.field = table.Fields[j]
                    break
                }
            }
            if (this.field !== null) {
                break
            }
        }
        this.imageArray = []
        for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
            if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
                if (this.dataSet[this.config.TableCode][i][this.config.OutFieldCode] !== this.dataSet[this.tablecode][this.rowindex][this.config.MasterFieldCode]) {
                    continue
                }
            }
            const result = await this.$api.getImageOrAffixUrl({ ResourceID: this.dataSet[this.config.TableCode][i][this.config.FieldCode], ReportID:0 })
            if (this.imageArray[i] !== undefined) {
                continue
            }
            this.imageArray.push({
                url: IMAGE_ROOT + result.imageinfo.Url ,
                status: '',
                message: '',
                id: this.dataSet[this.config.TableCode][i][this.config.FieldCode]
            })
        }
        console.log(this.field)
    },
    methods: {
        removeImageListV2 (file, detail) {
            this.deleteIndex = -1
            if (this.isEditSaved || !this.field.Editable) {
                return false
            }
            // this.editFieldBefore(this.config.TableCode, this.dataSet[this.config.TableCode].length, this.config.FieldCode)
            let index = detail.index
            if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
                for (let i = 0; i < this.dataSet[this.config.TableCode].length; i++) {
                    if (this.dataSet[this.config.TableCode][i][this.config.FieldCode] === file.id) {
                        index = i
                        break
                    }
                }
            }
            this.editFieldBefore(this.config.TableCode, index, this.config.FieldCode)
            this.deleteIndex = detail.index
            this.deleteFocuseRow(index, this.config.TableCode)
        },
        onClickUploadImageBefore () {
            // this.showPreloader()
            // this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            // this.onClickUploadImageBeforeV2()
        },
        changeUploadImage () {
            console.log(1122)
        },
        async onClickUploadImage1 (e) {
            console.log(this.dataSet)
            console.log(this.config)
            this.editFieldBefore(this.config.TableCode, this.dataSet[this.config.TableCode].length, this.config.FieldCode)
            const result = await this.editFieldBeforeV2()
            if (result) {
                if (this.config.OutFieldCode !== undefined && this.config.OutFieldCode !== null && this.config.OutFieldCode !== '') {
                    this.onClickUploadImageList(e, this.config.OutFieldCode, this.dataSet[this.tablecode][this.rowindex][this.config.MasterFieldCode])
                } else {
                    this.onClickUploadImageList(e)
                }
            }
            // this.hidePreloader()
        }
    }
}
</script>

<style scoped>

</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import mitt from 'mitt'
import utils from './utils'
import i18n from './locale'
import api from './api/api'
// import './plugins/element'
import './styles/index.less'
import { Col, Row, Badge, GoodsAction, GoodsActionIcon, GoodsActionButton,Overlay } from 'vant'
import 'vant/lib/index.css'
// 是否会影响放大缩小？
import '@vant/touch-emulator'
import '@/components/MyReport/componentsMobile/register-cpt'
import './plugins/index'
import voiceInputButton from 'voice-input-button2'

Vue.use(Overlay);
Vue.use(Badge);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Col);
Vue.use(Row);
Vue.use(Overlay);
window.NXCELLS_TOW = 0
// Vue.use(ElementUI);

// function MP(ak) {
//     return new Promise(function (resolve, reject) {
//         window.onload = function () {
//             resolve(BMap)
//         }
//         var script = document.createElement('script')
//         script.type = 'text/javascript'
//         script.src = 'https://api.map.baidu.com/api?v=2.0&ak=' + ak+ '&callback=initmap'
//         document.head.appendChild(script)
//         var script1 = document.createElement('script')
//         script1.type = 'text/javascript'
//         script1.src = 'https://api.map.baidu.com/api?v=2.0&ak=' + ak + '&type=webgl&callback=initmapgl'
//         document.head.appendChild(script1)
//     })
// }
// async function f() {
//     const result = await api.getBaiduMapAk()
//     const ak = result.Data
//     if (ak !== null && ak !== '') {
//         MP(ak)
//     }
// }
// f()
Vue.config.productionTip = false
Vue.prototype.$bus = mitt()
Vue.prototype.$utils = utils
Vue.prototype.$api = api
api.getXfYunKey().then((res) => {
    if(res.Code === 0 && res.ApiKey !== ''  && res.ApiSercet !== ''  && res.Appid !== '') {
        Vue.use(voiceInputButton, {
            appId: res.Appid, // 您申请的语音听写服务应用的ID
            apiKey: res.ApiKey, // 您开通的语音听写服务的 apiKey
            apiSecret: res.ApiSercet, // 您开通的语音听写服务的 apiSecret
            color: '#fff', // 按钮图标的颜色
            tipPosition: 'top', // 提示条位置
        })
    }
})
new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')

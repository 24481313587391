<template>
    <div style="position: relative;" v-if="field.Visable">
        <div class="myInput report-v2">
            <van-cell-group :style="'background-color: ' + config.backColor">
                <van-field :required="field.Nullable == true"
                           :label-width="config.labelWidth"  :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;background-color: ' + config.backColor" >
                    <template slot="label">
                        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + pageSetup.wordColor +'; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
            config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'">{{ config.name }}</div>
                    </template>
                    <template slot="input">
                        <!--{{config.labelWidth}}-->
                        <!--{{config.name}}-->
                        <input :placeholder="'弹出树形'"
                               @click.stop="editFieldBefor" readonly  :disabled="isEditSaved || !field.Editable"
                               v-model="dataSet[tablecode][rowindex][config.FieldCode]"
                               readonly
                               :style="'font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px;  height: ' +
          config.cellHeight + 'px; line-height: ' +  config.cellHeight + 'px; width:' + inputWidth
              + 'px;background-color: ' + config.backColor" />
                    </template>
                    <template #button>
                        <van-button v-if="field.Linkers != null  && field.Linkers.length > 0"  type="default" style="width: 12px;height: 12px;" :icon="require('@/assets/images/report/linker.png')" @click="linkReport()"></van-button>
                    </template>
                </van-field>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
import { Field, Popup, Dialog, Button, CellGroup } from 'vant'
export default {
    name: 'nxMobileTree',
    components: {
        [Field.name]: Field,
        [Dialog.name]: Dialog,
        [Popup.name]: Popup,
        [CellGroup.name]: CellGroup,
        [Button.name]: Button

    },
    props: {
        pageSetup: Object,
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        config: Object,
        label: String,
        isEditSaved: Boolean,
        rowindex: Number,
        tablecode: String,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        editFieldAfter: Function,
        linkReportV2: Function,
        changeValue: Function,
        onClickOpenTree: Function,
        componentType: {
            type: String,
            default: ''
        },
        updateField: Boolean
    },
    watch: {
        updateField () {
            let tableList = []
            tableList = this.$utils.deepClone(this.tables)
            tableList.push(this.mastertable)
            this.field = null
            for (let i = 0; i < tableList.length; i++) {
                const table = tableList[i]
                for (let j = 0; j < table.Fields.length; j++) {
                    if ((this.tablecode === undefined || table.TableCode === this.tablecode) && table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
                if (this.field !== null) {
                    break
                }
            }
        },
        config() {
            this.inputWidth = window.innerWidth - 20
            switch (this.config.col) {
                case '1/2':
                    this.inputWidth = this.inputWidth/2
                    break
                case '1/3':
                    this.inputWidth = this.inputWidth/3
                    break
                case '2/3':
                    this.inputWidth = this.inputWidth/3 * 2
                    break
                case '1/4':
                    this.inputWidth = this.inputWidth/4
                    break
                case '3/4':
                    this.inputWidth = this.inputWidth/4 * 3
                    break
            }
            this.inputWidth = this.inputWidth - this.config.labelWidth - 32
            if (this.componentType === 'group') {
                this.inputWidth -= 30
            }
        }
    },
    data() {
        return {
            dataSpecAllowManual: false,
            dataSpecAllowMultSelect: false,
            weightList: {0: 'inherit', 33: 'normal', 66: 'bold', 99: 'bolder'},
            sizeList: {0: '10', 33: '14', 66: '16', 99: '20'},
            field: null,
            editors: [],
            showLists: false,
            showList: false,
            searchDataListInput: '',
            loadingDataList: false,
            finishedDataList: false,
            imageDataList: [],
            dataSpecSummaryList: [],
            dataSpecFields: [],
            dataSpecInputData: {},
            localTable: {},
            inputWidth: 60
        }
    },
    mounted() {
        let tableList = []
        tableList = this.$utils.deepClone(this.tables)
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            for (let j = 0; j < table.Fields.length; j++) {
                if ((this.tablecode === undefined || table.TableCode === this.tablecode) && table.Fields[j].FieldCode === this.config.FieldCode) {
                    this.field = table.Fields[j]
                }
            }
            if (this.field !== null) {
                break
            }
        }
    },
    methods: {
        linkReport() {
            this.linkReportV2(this.tablecode, this.config.FieldCode, this.rowindex)
        },
        async editFieldBefor() {
            console.log(11)
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            const result = await this.editFieldBeforeV2()
            if (result) {
                this.onClickOpenTree(this.field, this.rowindex)
            }
            // if (this.field.Editor !== undefined && this.field.Editor !== null) {
            //     const resultDataSpecID = await this.$api.getDataSpecID({
            //         DataSpecID: this.field.Editor.DataSpecID
            //     })
            //     if (resultDataSpecID.Code === 0) {
            //         this.field.Editor.DataSpecID = resultDataSpecID.Data
            //     }
            // } else {
            //     for (let i = 0; i < this.field.Editors.length; i++) {
            //         const resultDataSpecID = await this.$api.getDataSpecID({
            //             DataSpecID: this.field.Editors[i].DataSpecID
            //         })
            //         if (resultDataSpecID.Code === 0) {
            //             this.field.Editors[i].DataSpecID = resultDataSpecID.Data
            //         }
            //     }
            // }
        }

    }
}
</script>

<style scoped>

</style>
<template>
  <div class="report-v2" style="position: relative;"  v-if="field.Visable">
    <van-field  :required="field.Nullable == true"
                :label-width="config.labelWidth" :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;background-color: ' + config.backColor" >
      <template slot="label">
        <div v-if="config.labelWidth !== '0'" :style="'width: ' + config.labelWidth + 'px; color: ' + pageSetup.wordColor +'; font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
            config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'">{{ config.name }}</div>
      </template>
      <template slot="input">
        <input :placeholder="config.description"  name="number" type="number"
               :disabled="isEditSaved || !field.Editable"
               v-model="dataSet[tablecode][rowindex][config.FieldCode]" @change="changeV" @focus="editFieldBefor"
               :style="'font-weight: ' + weightList[pageSetup.wordWeight] + '; font-size: ' + sizeList[pageSetup.wordSize] + 'px; height: ' +
          config.cellHeight + 'px; line-height: ' +  config.cellHeight
              + 'px;background-color: ' + config.backColor" />
      </template>
      <template #button>
        <van-button v-if="field && field.Linkers != null  && field.Linkers.length > 0"  size="small" type="default" :icon="require('@/assets/images/report/linker.png')" @click="linkReport()"></van-button>
      </template>
    </van-field>
  </div>
</template>

<script>
import { Field, Button } from 'vant'
export default {
    name: 'nxMobileNumber',
    components: {
        [Field.name]: Field,
        [Button.name]: Button
    },
    props: {       pageSetup: Object,
        mastertable: Object,
        tables: Array,
        dataSet: Object,
        config: Object,
        label: String,
        isEditSaved: Boolean,
        rowindex: Number,
        tablecode: String,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        editFieldAfter: Function,
        linkReportV2: Function,
        updateField: Boolean
    },
    data () {
        return {
            weightList: {                 0: 'inherit',                 33: 'normal',                 66: 'bold',                 99: 'bolder'             },             sizeList: {                 0: '10',                 33: '14',                 66: '16',                 99: '20'             },             field: null
        }
    },
    watch: {
        updateField () {
            let tableList = []
            tableList = this.$utils.deepClone(this.tables)
            tableList.push(this.mastertable)
            this.field = null
            for (let i = 0; i < tableList.length; i++) {
                const table = tableList[i]
                for (let j = 0; j < table.Fields.length; j++) {
                    if ((this.tablecode === undefined || table.TableCode === this.tablecode) && table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
                if (this.field !== null) {
                    break
                }
            }
        }
    },
    mounted () {
        const tableList = this.tables
        tableList.push(this.mastertable)
        for (let i = 0; i < tableList.length; i++) {
            const table = tableList[i]
            if (table.TableCode === this.tablecode) {
                for (let j = 0; j < table.Fields.length; j++) {
                    if (table.Fields[j].FieldCode === this.config.FieldCode) {
                        this.field = table.Fields[j]
                    }
                }
            }
            if (this.field !== null) {
                break
            }
        }
    },
    methods:{
        changeV () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldAfter()
        },
        editFieldBefor () {
            this.editFieldBefore(this.tablecode, this.rowindex, this.config.FieldCode)
            this.editFieldBeforeV2()
        },
        linkReport () {
            this.linkReportV2(this.tablecode, this.config.FieldCode, this.rowindex)
        }
    }
}
</script>

<style scoped>

</style>

<template>
  <div v-if="showButton" :style="'position: relative; height:46px; text-align:' + config.align">
    <div :style="'margin-top:5px; margin-bottom: 5px; width: 100%; height: ' + config.cellHeight + 'px;'">
      <van-button :disabled="isEditSaved"  @click.stop="buttonClick"  type="small" :color="config.backColor" style="border-radius: 5px; margin-left: 2%; height: 35px; width: 96%" >{{config.text}}
      </van-button>
    </div>
    <!--<van-button type="small" :color="config.backColor"  :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >-->
      <!--<div :style="'color:' + pageSetup.wordColor +'; width:' + config.width + 'px;'">{{config.text}}</div>-->
    <!--</van-button>-->
  </div>
</template>

<script>
    import { Field, Button } from 'vant'
    export default {
        name: 'nxMobileButton',
        components: {
            [Field.name]: Field,
            [Button.name]: Button
        },
        props: {
            mastertable: Object,
            tables: {
                type: Array,
                default: ([])
            },
            pageSetup: Object,
            value: String,
            label: String,
            config: Object,
            children: Array,
            field: Object,
            isEditSaved: Boolean,
            doAction: Function,
            doActionV2: Function,
            execConsole: Function,
            dataRow: Object,
            isList: {
                type: Boolean,
                defalut: false
            },
            dataSet: Object,
            customRcidFieldname: String,
            rowindex: Number,
        },
        data () {
            return {
                show: false,
                showButton: true
            }
        },
        watch: {
            dataRow () {
                console.log(this.dataRow)
            },
            dataSet () {
                if (!this.isList && this.config.exprShow && this.config.exprShow !== null && this.config.exprShow !== '') {
                    let expr = this.config.exprShow
                    if(this.dataSet) {
                        for (let i = 0; i < this.mastertable.Fields.length; i++) {
                            const field = this.mastertable.Fields[i]
                            if (this.dataSet[this.mastertable.TableCode][0]) {
                                expr = expr.replaceAll('[' + this.mastertable.TableCode + '].[' + field.FieldCode + ']', '\'' + this.dataSet[this.mastertable.TableCode][0][field.FieldCode] + '\'')
                            }
                        }
                        for (let j = 0; j < this.tables.length; j++) {
                            for (let i = 0; i < this.tables[j].Fields.length; i++) {
                                const field = this.tables[j].Fields[i]
                                if (this.dataSet[this.tables[j].TableCode][this.rowindex]) {
                                    expr = expr.replaceAll('[' + this.tables[j].TableCode + '].[' + field.FieldCode + ']', '\''+ this.dataSet[this.tables[j].TableCode][this.rowindex][field.FieldCode] + '\'')
                                }
                            }
                        }
                        expr = expr.replaceAll (' = ', ' == ')
                        expr = expr.replaceAll (' <> ', ' != ')
                        expr = expr.replaceAll (' 并且 ', ' && ')
                        expr = expr.replaceAll (' 或者 ', ' || ')
                        this.showButton = eval(expr)
                    } else {
                        this.showButton = true
                    }
                } else {
                   this.showButton = true
                }
            }
        },
        methods: {
            buttonClick (event) {
                const listAction = []
                listAction.push(this.config.actionID)
                if (this.isList) {
                    this.doAction(listAction, this.dataRow['sys'].ReportID, this.dataRow['sys'][this.customRcidFieldname], this.config.refreshType)
                } else {
                    if (this.config.actionType === '2') {
                        this.execConsole(this.config.actionID)
                    } else {
                        if (this.config.actionID.toString().indexOf('nxmobileAction') === 0) {
                            this.doActionV2(this.config.actionID)
                        } else {
                            this.doAction(listAction)
                        }
                    }
                }
                event.stopPropagation()
            }
        }
    }
</script>

<style scoped>

</style>

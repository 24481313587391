<template>
  <div style="position: relative; margin-top: 10px;">
    <div class="tab_name" v-if="config.showLabel">
      <div class="tab_pro" id="tab_pro">{{config.label}}</div>
    </div>
    <div class="tab_ct" name="tab_one" id="dt_one" style="background-color: #ffffff; border-radius: 0px 8px 8px 8px;">
      <div  v-if="config.showLabel" style="height: 3px;"></div>
      <div v-for="(row, rowindex) in dataSet[config.TableCode]" :key="rowindex" >
        <div style="display: block; margin-left: 10px; margin-right: 10px; background-color: #ffffff; border-radius: 8px; box-shadow: 0px 0px 5px #ccc" v-if="config.componentJson">
          <component
                  :page-setup="pageSetup"
                  :is="item.component"
                  v-for="(item, index) in config.componentJson.pageComponents"
                  :table="item.table"
                  :data-set="dataSet"
                  :key="index"
                  :config="item.setStyle"
                  :id="item.id"
                  :style="{
                  float: 'left',
                  width: item.setStyle === undefined ? '100%' : item.setStyle.col === '1/2' ? '50%' :item.setStyle.col === '1/3' ? '33%' :item.setStyle.col === '2/3' ? '66%' : item.setStyle.col === '1/4' ? '25%' : item.setStyle.col === '3/4' ? '75%' : '100%'
                }"
                  :is-edit-saved="isEditSaved"
                  :resource-home = 'resourceHome'
                  :on-click-upload-image="onClickUploadImage"
                  :pop-image-info="popImageInfo"
                  :on-click-show-image="onClickShowImage"
                  :remove-image="removeImage"
                  :remove-affix="removeAffix"
                  :rowindex = "rowindex"
                  :tablecode = "config.TableCode"
                  :change-value="changeValue"
                  :edit-field-before="editFieldBefore"
                  :edit-field-before-v2="editFieldBeforeV2"
                  :on-click-upload-affix="onClickUploadAffix"
                  :pop-affix-info="popAffixInfo"
                  :preview-affix="previewAffix"
                  :edit-field-after="editFieldAfter"
                  :field="item.setStyle.field"
                  :tables="tables"
                  :mastertable="mastertable"
                  class="componentsClass"
                  :on-click-open-list="onClickOpenList"
                  :on-click-open-tree="onClickOpenTree"
                  :sg-bar-code="sgBarCode"
                  :link-report-v2 = "linkReportV2"
                  :template-id="templateId"
                  :update-field="updateField"
          >
          </component>
          <div style="height: 10px; position: relative; float: left; width: 100%;"></div>
        </div>
      </div>
      <div  style="display: inline;color: #1989fa; position: absolute; top: 40px; right: 0;float: right;" @click="addFocuseRow(config.TableCode, true)"  v-if="!isEditSaved && !IsQuery  && table.CanAddRows">
        <van-icon name="add-o" size="24" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Row, Col, Button, Collapse, CollapseItem, SwipeCell } from 'vant'
export default {
  name: 'nxMobileMycomponent',
  components: {
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [SwipeCell.name]: SwipeCell
  },
  props: {
      templateId: String,
      pageSetup: Object,
      mastertable: Object,
      tables: Array,
      value: String,
      config: Object,
      children: Array,
      field: Object,
      cssdiy: Object,
      label: String,
      isEditSaved: Boolean,
      onClickUploadImage: Function,
      popImageInfo: Function,
      onClickShowImage: Function,
      removeImage: Function,
      removeAffix: Function,
      resourceHome: String,
      dataSet: Object,
      changeValue: Function,
      addFocuseRow: Function,
      editFieldBefore: Function,
      editFieldBeforeV2: Function,
      deleteFocuseRow: Function,
      popAffixInfo: Function,
      previewAffix: Function,
      onClickUploadAffix: Function,
      editFieldAfter: Function,
      linkReportV2: Function,
      onClickOpenList: Function,
      onClickOpenTree: Function,
      sgBarCode: Function,
      updateField: Boolean
  },
  data () {
    return {
      deleShow: true,
        table: null
    }
  },
  mounted () {
      for (let i = 0; i < this.tables.length; i++) {
          if (this.tables[i].TableCode === this.tablecode) {
              this.table = this.tables[i]
              break
          }
      }
  },
  methods: {
    rowSchem (dataSet, config, rowIndex) {
      let strSchem = ''
      for (let i = 0; i < config.fieldScheam.length; i++) {
        const field = config.fieldScheam[i]
        strSchem += field.FieldTitle + ':值  '
      }
      return strSchem
    }
  }
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./nxMobileTable.vue?vue&type=template&id=3b4f49e0&scoped=true&"
import script from "./nxMobileTable.vue?vue&type=script&lang=js&"
export * from "./nxMobileTable.vue?vue&type=script&lang=js&"
import style0 from "./nxMobileTable.vue?vue&type=style&index=0&id=3b4f49e0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b4f49e0",
  null
  
)

export default component.exports
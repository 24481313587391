<template>
  <div style="position: relative; background-color: white;">
    <div style="margin-left: 10px;" v-if="taskroutes.length > 0 || todotasks.length > 0">
      <div style="color: #97a0b3; margin-top: 5px;">流程</div>
      <div style="margin-top: 10px;">
        <component v-for="(item, index) in taskroutes" :key="index">
          <van-row v-if="item.DealType !== '创建'">
            <van-col span="2">
              <van-image
                      round
                      width="2rem"
                      height="2rem"
                      :src="imageRoot+item.DealUserPhotoUrl"
              />
            </van-col>
            <van-col span="1"></van-col>
            <van-col span="15">
              <div style="font-size: 12px;">{{item.TaskName}}</div>
              <div style="font-size: 6px;"><span style="color: #97a0b3">{{item.DealUser}}</span><span v-if="item.TaskMsg.length > 0">（<span style="color: blue;">{{item.TaskMsg}}</span>）</span></div>
            </van-col>
            <van-col span="6" style=" font-size: 10px;">
              {{formatDateTimeStr(item.DealTime, 3)}}
            </van-col>
          </van-row>
          <div style="height: 1rem; width: 1rem; border-right:2px solid #aaaaaa; margin-bottom: 4px;"  v-if="item.DealType !== '创建'"></div>
        </component>
        <component v-for="(item, index) in todotasks" :key="index">
          <van-row v-if="item.DealType !== '创建'">
            <van-col span="2">
              <van-image
                      round
                      width="2rem"
                      height="2rem"
                      :src="imageRoot+item.DealUserPhotoUrl"
              />
            </van-col>
            <van-col span="1"></van-col>
            <van-col span="21">
              <div style="font-size: 12px;">{{item.TaskName}}（<span style="color: blue; font-size: 6px;">待办</span>）</div>
            </van-col>
          </van-row>
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import { Row, Col, Image } from 'vant'
import {IMAGE_ROOT} from '../../../../com/config'
export default {
  name: 'nxMobileProgress',
  components: {
    [Image.name]: Image,
    [Row.name]: Row,
    [Col.name]: Col
  },
    props: {
        pageSetup: Object,
        mastertable: Object,
        tables: {
            type: Array,
            default: ([])
        },
        dataSet: Object,
        config: Object,
        label: String,
        isEditSaved: Boolean,
        rowindex: Number,
        tablecode: String,
        editFieldBefore: Function,
        editFieldBeforeV2: Function,
        editFieldAfter: Function,
        linkReportV2: Function,
        dataRow: Object,
        changeValue: Function,
        isList: {
            type: Boolean,
            default: false
        },
        sgBarCode: Function,
        taskId: String,
        recordId: String
    },
    data () {
      return {
          taskroutes: [],
          todotasks: [],
          imageRoot: ''
      }
    },
    async mounted () {
        this.imageRoot = IMAGE_ROOT
        let taskid = this.taskId
        if(this.taskId == "0")
        {
            const resultLastTaskIDByRecordID = await this.$api.getLastTaskIDByRecordID({ReportID: this.recordId})
            if (resultLastTaskIDByRecordID.TaskID === '') {
                this.showAlert('当前模板没有绑定任务流!')
                return;
            } else {
                taskid = resultLastTaskIDByRecordID.TaskID
            }
        }
        const resultTaskRouteList = await this.$api.getTaskRouteList({TaskID: taskid})
        console.log(resultTaskRouteList)
        if (resultTaskRouteList.Code === 0) {
            this.taskroutes = resultTaskRouteList.TaskRoutes
            for (let i = 0; i < this.taskroutes.length; i++) {
                const item = this.taskroutes[i]
                item.DealUserPhotoUrl = await this.getImage(item.DealUserPhotoUrl)
            }
            this.todotasks = resultTaskRouteList.TodoTasks
            for (let i = 0; i < this.todotasks.length; i++) {
                const item = this.todotasks[i]
                item.DealUserPhotoUrl = await this.getImage(item.DealUserPhotoUrl)
            }
        }
    },
    methods: {
        // 格式时间字符串
        formatDateTimeStr(date, type) {
            if (date === '' || !date) {
                return ''
            }
            var dateObject = new Date(date)
            var y = dateObject.getFullYear()
            var m = dateObject.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = dateObject.getDate()
            d = d < 10 ? ('0' + d) : d
            var h = dateObject.getHours()
            h = h < 10 ? ('0' + h) : h
            var minute = dateObject.getMinutes()
            minute = minute < 10 ? ('0' + minute) : minute
            var second = dateObject.getSeconds()
            second = second < 10 ? ('0' + second) : second
            if (type === 1) { // 返回年月日
                return y + '-' + m + '-' + d
            } else if (type === 2) { // 返回年月日 时分秒
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
            } else if (type === 3) { // 返回年月日 时分秒
                return m + '-' + d + ' ' + h + ':' + minute
            }
        },
        async getImage (photo) {
            const defaultPhoto = 'img/mobile/user/torvalds.png'
            const result = await this.$api.get(IMAGE_ROOT + photo)
            if (result.status === 404) {
                return defaultPhoto
            } else {
                return photo
            }
        }
    }
}
</script>

<style scoped>

</style>
